import(/* webpackMode: "eager" */ "C:\\Users\\jjend\\Desktop\\portfolio\\node_modules\\next\\dist\\client\\image-component.js");
;
import(/* webpackMode: "eager" */ "C:\\Users\\jjend\\Desktop\\portfolio\\node_modules\\next\\dist\\client\\link.js");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "C:\\Users\\jjend\\Desktop\\portfolio\\src\\components\\Approach.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["PinContainer"] */ "C:\\Users\\jjend\\Desktop\\portfolio\\src\\components\\ui\\3d-pin.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["BentoGrid","BentoGridItem"] */ "C:\\Users\\jjend\\Desktop\\portfolio\\src\\components\\ui\\BentoGrid.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["FloatingNav"] */ "C:\\Users\\jjend\\Desktop\\portfolio\\src\\components\\ui\\FloatingNav.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["Button"] */ "C:\\Users\\jjend\\Desktop\\portfolio\\src\\components\\ui\\MovingBorders.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["TextGenerateEffect"] */ "C:\\Users\\jjend\\Desktop\\portfolio\\src\\components\\ui\\TextGenerateEffect.tsx");
